#apercu-icone {
    width: 64px;
    height: auto;
    margin: var(--default-margin) 0;
    padding: var(--default-padding);
    border: 1px solid var(--shadow-color);
    border-radius: 16px;
    background-color: var(--main-menu-bg-color);
}

#apercu-logo {
    width: 400px;
    height: auto;
    margin: var(--default-margin) 0;
    padding: var(--default-padding);
    border: 1px solid var(--shadow-color);
    border-radius: 16px;
    background-color: var(--app-bg-color);
}

#mentions .text-field, #mentions .input-field {
    width: 100%;
}