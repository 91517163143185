.password-reset {
    font-family: var(--app-font-family);
    font-weight: var(--app-font-weight);
    font-size: var(--app-font-size);
}

#strength {
    color: white;
    padding: var(--default-padding);
    border-radius: 16px;
    min-width: 250px;
    text-align: center;
}

.ok {
    background-color: darkgreen;
}

.warning {
    background-color: orange;
}

.critical {
    background-color: darkred;
}