.data-grid {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

.data-grid tr {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.data-grid th,
.data-grid td {
    box-sizing: border-box;
}

.data-grid tbody,
.data-grid thead {
    display: block;
}

.data-grid tbody {
    overflow: auto;
}