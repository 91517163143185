.color-picker {
    display: flex;
    flex-direction: column;
    align-content: center;
    position: relative;
}

.color-picker.inline {
    flex-direction: row;
}

.color-picker label {
    text-transform: uppercase;
    font-family: var(--label-font-family);
    font-size: var(--label-font-size);
    font-weight: var(--label-font-weight);
    margin: 8px 4px;
}

.color-picker.inline label {
    text-transform: none;
    font-size: var(--input-font-size);
    padding-left: var(--default-padding);
    padding-right: 0;
    margin: 4px 0;
}

.color-picker.inline.before label {
    padding-left: 0;
    padding-right: var(--default-padding);
}

.color-picker input {
    border-radius: var(--default-radius);
    border: 1px solid var(--shadow-color);
    height: 24px;
}

.color-picker.xs input, .color-picker.xsmall input {
    width: var(--xsmall);
}

.color-picker.small  input {
    width: var(--small);
}

.color-picker.normal input {
    width: var(--normal);
}

.color-picker.large input {
    width: var(--large);
}

.color-picker.xlarge input {
    width: var(--xlarge);
}

