@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
@import url('https://fonts.googleapis.com/css?family=Inter:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');

#root {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.App {
  display: flex;
  flex-direction: row;
  flex: 1 0;
  width: 100vw;
  height: 100vh;
}

div {
  box-sizing: border-box;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.hidden {
  display: none !important;
}

.scroll {
  overflow: auto;
  height: auto !important;
}

.scrollY {
  overflow-y: auto;
}

.scrollX {
  overflow-x: auto;
}

::-webkit-scrollbar {
  width: 8px; 
  height: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background: var(--button-bg-color);
}

.disabled, .disabled input, .disabled textarea {
  pointer-events: none;
  cursor: not-allowed;
}

blockquote {
  display:inline;
  flex-direction: column;
  flex: 1 0;
  height:fit-content;
  border-left: 5px solid var(--blockquote-border-color);
  background-color: var(--blockquote-bg-color);
  color: var(--blockquote-fg-color);
  padding: var(--default-margin);
  text-align: justify;
  margin:0;
}

button.disabled {
  background-color: lightgray;
}

.edit {
  color: var(--app-edit-colorr);
}
.delete {
  color: var(--app-delete-color);
}

.small {
  width: var(--small);
}
.xs {
  width: var(--xs);
}

.normal {
  width: var(--normal);
}

.large {
  width: var(--large);
}

.xlarge {
  width: var(--xlarge);
}
.xxlarge {
  width: var(--xlarge);
}

table {
  border: none;
  border-collapse: collapse;
  padding: 0;
  margin: 0;
  font-family: var(--app-font-family);
  font-size: var(--app-font-size);
  width: 100%;
}

table tbody tr:first-child td {
  padding-top: 16px;
}

table td {
  height: 20px;
}

tr:nth-child(odd) {
  background: var(--table-row-odd-bg-color); 
}

tr:nth-child(even) {
  background: var(--table-row-even-bg-color); 
}

table tbody tr:last-child {
  border-bottom: 1px solid var(--table-header-border-color);
}

th { 
  font-weight: var(--font-weight);
  padding: 8px;
  text-align: left;
  background-color: var(--table-header-bg-color);
  color: var(--table-header-fg-color);
}

td {
  padding: 8px;
}

.table-placeholder {
  display: block;
  margin: 16px 0 16px 0;
}

table.mono tr:nth-child(odd) {
  background: var(--table-row-mono-bg-color); 
}

table.mono tr:nth-child(even) {
  background: var(--table-row-mono-bg-color); 
}

.badge {

  position: relative;
  left: 5px;
  top: -1px;
  background-color: red;
  color: white;
  display: inline-block;
  height: 5px;
  width: 5px;
  border-radius: 50%;
}
.Graph a {
  font-size: 0 !important;
}
