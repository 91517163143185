table.repartTrim {
    table-layout: fixed;
}

table.repartTrim th {
    width: 50px !important;
    text-align: center;
    font-size: 8pt;
}

table.repartTrim tbody tr {
    border: 0;
}

table.repartTrim td {
    width: 100px !important;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
}

table.repartTrim td .text {
    width: 40px !important;
    margin: 0 auto;
}

table.repartTrim td  input {
    width: 30px !important;
    text-align: center !important;
}
