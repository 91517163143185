.text-field {
    display: flex;
    flex-direction: column;
    align-content: center;
    position: relative;
}

.text-field label {
    text-transform: uppercase;
    font-family: var(--label-font-family);
    font-size: var(--label-font-size);
    font-weight: var(--label-font-weight);
    margin: 8px 4px;
}

.text-field input {

    border: 1px solid var(--shadow-color);
    border-radius: var(--default-radius);
    height: 24px;
    line-height: 24px;
    padding: 3px 4px;
    outline: none;
    font-family: var(--app-default-font);
    font-size: var(--input-font-size);
    font-weight: var(--app-font-weight);
    background-color: var(--input-bg-color);
    color: var(--input-fg-color);
}

.text-field.disabled input {
    background-color: var(--app-disabled-bg-color);
}

.text-field textarea {

    border: 1px solid var(--shadow-color);
    border-radius: var(--default-radius);
    padding: 4px;
    outline: none;
    font-family: var(--app-default-font);
    font-size: var(--input-font-size);
}

.text-field input:focus, .text-field textarea:focus {
    box-shadow: 0 0 4px var(--input-active-border-color);
}

.text-field input::placeholder, .text-field textarea::placeholder {
    color: var(--shadow-color);
}

.text-field .input-field {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.text-field .input-field input {
    box-sizing: border-box;
    height: 32px;
    line-height: 32px;
    padding-bottom: 2px;
}

.text-field .input-field.with-icon-left input {
    padding-left: 32px;
}

.text-field .input-field.with-icon-right input {
    padding-right: 32px;
}

.text-field.xs input, .text-field.xsmall input {
    width: var(--xsmall);
}

.text-field.small  input {
    width: var(--small);
}

.text-field.normal input {
    width: var(--normal);
}

.text-field.large input {
    width: var(--large);
}

.text-field.xlarge input {
    width: var(--xlarge);
}

.text-field div.icon {
    position: absolute;
    width: 16px;
    height: 16px;
    margin: 9px 8px;
    padding: 0;

}

.text-field div.icon-left {
    left: 0;
}

.text-field div.icon-right {
    right: 0;
}

.text-field svg, .text-field .button svg {
    padding: 0;
}

.invisible input{
    text-align:center !important;
    background:none;
    border:none;
    color:white;
    height: 20px !important;
}
.invisibleRachatTrimestre-left input{
    text-align:left !important;
    background:none;
    border:none;
    color:white;
}
.invisibleRachatTrimestre input{
    text-align:center !important;
    background:none !important;
    border:none;
    color:white;
}
.invisibleRachatTrimestre-left.disabled input,.invisibleRachatTrimestre.disabled input {
    background-color: transparent;
}