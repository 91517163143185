.blockRoundedForce{
    padding: 8px 16px 8px 16px !important;
    border-radius: var(--default-radius)!important;
    box-shadow: var(--default-shadow) !important;
}

.simulation tbody tr:last-child {
    border-bottom: none;
}
table.simulation tbody tr:first-child td {
    padding: 0;
}
table.simulation td {
    position: relative;
    height: 40px;
    padding: 0;
    margin: 0;
    font-size: 9pt;
}

table.simulation th {
    text-align: center;
    vertical-align: center;
}

table.simulation tr:nth-child(odd) {
    background: transparent; 
}

table.simulation tr:nth-child(even) {
    background: transparent;
}
table.simulation tr td {
    text-align: right;
    white-space: nowrap;
}

table.simulation tr td:first-child {
    text-align: left;
    white-space: normal;
}

table.simulation td.libelle {
   min-width: 230px;
}

table.simulation td.libelleGras {
    width: 100%;
    font-weight: bold;
}

table.simulation td.origine {
    width: 100px;
}
table.simulation td.space {
    width: 80px;
}

table.simulation td.nouvelle>.text-field {
    float: right;
}
table.simulation td.nouvelle {
    width: 120px;
}

table.simulation td.extra {
    min-width: 30px;
}

table.simulation td.ecart {
    min-width: 90px;
}

table.simulation td.avant {
    min-width: 125px;
}

table.simulation td.apres {
    min-width: 125px;
}
table.simulation td.pr4 {
    padding-right:4px !important;
}


.optiIcon {
    height: 44px;
    margin: 0;
    font-size: 0.875rem;
    box-shadow: 1px 1px 2px rgba(0,0,0,.5);
    border-radius: 50%;
    background-color: var(--app-selected-bg-color);
}

table.simulation .editIconOptiRem {
    position: absolute;
    left: 6px;
    color: var(--app-selected-bg-color) !important;
    margin: 0;
    padding: 0;
}
table.simulation .optiDetailRem {
    position: absolute;
    left: 7px;
    top: 14px;
}


table.cotProductive tr:first-child td {
    margin: 0;
    padding: 8px;
}
table.cotProductive tr:nth-child(even) {
    background: transparent;
}

table.cotProductive tr:last-child {
    border-bottom: none;
}
table.cotProductive tr td:first-child {
    text-align: left;
    white-space: nowrap;
    width: 100%;
}
table.cotProductive tr td {
    text-align: right;
    white-space: nowrap;
}
table.cotProductive td+td {
   min-width: 90px;
}

.tableArbitrage td{
   height : 33px !important;

}
.blockRoundedForceReduced {
    padding: 3px 16px 3px 16px !important;
    border-radius: var(--default-radius)!important;
    box-shadow: var(--default-shadow) !important;
}