.loader-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    background-color: none;
    backdrop-filter: blur(2px);
    z-index: 1000000;
    justify-content: center;
}

.loader {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    background-color: whitesmoke;
    border-radius: 4px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, .25);
    padding: 32px;
}

.loader img {
    width: 48px;
    margin: 32px;
}

.loader p {
    margin: 0 0 8px 0;
}