.user-panel-actual {
    font-size: var(--app-font-small);
    font-style:italic;
}

.user-panel-association {
    font-size: var(--app-font-small);
    font-style:italic;
}

.user-panel-disconnect svg {
    color: var(--app-bg-color);
}

.user-panel svg {
    width: 24px;
    height: 24px;
}

.user-panel-settings svg {
    color: var(--button-fg-color);
}
