.dark-title, .dark-title th, .dark-title td { 
    background: var(--button-bg-color) !important;
    color: var(--app-bg-color) !important;
}
.light-title, .light-title th, .light-title td { 
    background: var(--header-bg-color) !important;
    color: var(--app-bg-color) !important;
}
.light-text { 
    color: var(--header-bg-color) !important;
}

table.resultat tr:first-child td {
    margin: 0;
    padding: 8px;
}
table.resultat tr:last-child {
    border-bottom: none;
}
table.resultat th {
    text-align: center;
    vertical-align: center;
}

table.resultat tr:nth-child(odd) {
    background: transparent; 
}

table.resultat tr:nth-child(even) {
    background: transparent;
}

table.resultat tr td {
    text-align: right;
    white-space: nowrap;
}

table.resultat tr td:first-child {
    text-align: left;
    white-space: normal;
}


table.cotisations tr:first-child td, table.detailImpot tr:first-child td,table.detailPension tr:first-child td{
    margin: 0;
    padding: 8px;
}

table.cotisations th,table.detailImpot th {
    text-align: center;
    vertical-align: center;
}

table.cotisations tr td,table.detailImpot tr td {
    text-align: right;
    white-space: nowrap;
}
table.cotisations tr td,table.detailImpot tr td+td+td {
    text-align: center;
    white-space: nowrap;
}

table.cotisations tr td:first-child,table.detailImpot tr td:first-child  {
    text-align: left;
    white-space: normal;
}

.bg-white{
    background-color: white !important;
}

.ficheDePaie {
    font-size: 8pt;

}

.ficheDePaie tbody tr:first-child td {
    padding-top: 8px;
}
.ficheDePaie td {
    height:10px;
    padding: 4px;
}

table.prestas tr:first-child td {
    margin: 0;
    padding: 8px;
}
table.prestas tr:nth-child(even) {
    background: transparent;
}

table.prestas tr:last-child {
    border-bottom: none;
}
table.prestas tr td:first-child {
    text-align: left;
    white-space: nowrap;
    width: 100%;
}
table.prestas tr td {
    text-align: right;
    white-space: nowrap;
}
table.prestas td+td {
   min-width: 125px;
}