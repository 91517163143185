div.card-box {
    display:flex;
    flex-direction: row;
    background-color: var(--app-bg-color);
    color: var(--app-fg-color);
    padding: var(--default-margin); 
    border-radius: var(--default-radius);
    box-shadow: var(--default-shadow);
}

div.card-box .col .row > h1 {
    display: block;
    width: 100%;
    height: 24px;
    padding: var(--default-padding);
    font-family: var(--app-font-family);
    font-weight: var(--app-font-bold);
    font-size: 9pt;
    background-color: var(--title-bg-color);
    color: var(--title-fg-color);
    border-bottom: 1px solid var(--title-fg-color);
    text-align: left;
    margin: 0 0 var(--default-margin) 0;
    text-transform: uppercase;
    box-sizing: border-box;
}

.modal .card-box {
    box-shadow: none;
    padding: 0;
}
