.dialog-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    background-color: var(--shadow-color);
    backdrop-filter: blur(4px);
    z-index: 10000;
    justify-content: center;
}

.dialog-frame {
    background-color: var(--app-bg-color);
    border-radius: 16px;
    min-width: 400px;
    max-width: 80%;
    padding: 0 !important;
    box-shadow: var(--default-shadow);
}

.dialog-title {
    background-color: var(--modal-header-bg-color);
    color: var(--modal-header-fg-color);
    font-size: 12pt;
    font-weight: var(--app-font-weight);
    min-height: 32px !important;
    max-height: 32px;
    width: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 16px 16px 0 0;
    overflow: hidden;
}

.dialog-icon {
    color: var(--button-bg-color);
    align-self: center;
}

.dialog-icon svg {
    width: 64px;
    height: 64px;
    margin-right: var(--default-margin);
}

.dialog-content {
    align-items: flex-start;
    padding-left: 64px;
    font-size: var(--app-font-size);
    align-self: center;
}

.dialog-buttons {
    width: 100%;
    margin: 0;
    border-top: 1px solid lightgrey;
    justify-content: center;
}