.pager {
    padding: 0 !important;
}

.pager-content {
    padding: 0 !important;
    overflow: auto;
}

.pager-buttons {
    height: 64px;
    align-items: center;
    padding: 0 var(--default-margin);
    border-top: 1px solid var(--shadow-color);
}

.pager-buttons .button {
    min-width: 120px;
}

.pager-buttons-left .button {
    margin-right: var(--default-margin);
}

.pager-buttons-right .button {
    margin-left: var(--default-margin);
}
