.hover-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--button-bg-color);
    color: var(--button-fg-color);
    width: 20px;
    height: 20px;
    border-radius: 16px;
    cursor:help;
}

.hover-content {
    position:absolute;
    background-color: var(--app-bg-color);
    box-shadow: var(--default-shadow);
    border-radius: var(--default-radius);
    border: 1px solid var(--shadow-color);
    padding: var(--default-padding);
}