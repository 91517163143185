.file-field {
    display: flex;
    flex-direction: column;
    align-content: center;
    position: relative;
}

.file-field-label {
    text-transform: uppercase;
    font-family: var(--label-font-family);
    font-size: var(--label-font-size);
    font-weight: var(--label-font-weight);
    margin: 8px 4px;
}

.file-field-button {
    display: flex;
    flex-direction: row;
    background-color: var(--button-bg-color);
    color: var(--button-fg-color);
    border: none;
    border-radius: var(--default-radius);
    box-shadow: var(--default-shadow);
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    min-height: 40px;
    min-width: 48px;
    font-family: var(--button-font-family);
}

.file-field-button svg {
    padding: 0 var(--default-padding);
}

@media (hover: hover) and (pointer: fine) {
    .file-field-button:not(:disabled):hover {
        filter: brightness(120%);
        border: none;
    }
}

.file-field-button:not(:disabled):active {
    transform: translate(2px, 2px);
    box-shadow: none;
}

.file-field-button span {
    padding: var(--default-padding);
}

.file-field-button.tool {
    flex-direction: column;
}

.file-field-button.icon {
    background-color: transparent;
    color: inherit;
    padding: 0;
    box-shadow: none;
    border: none;
    border-radius: 0;
    min-width: 16px;
    min-height: 16px;
}

.file-field-button.disabled {
    background-color: var(--button-disabled-bg-color);
}

.file-field input {
    display: none;
}

.file-field.xs .file-field-button, .file-field.xsmall .file-field-button {
    width: var(--xsmall);
}

.file-field.small .file-field-button {
    width: var(--small);
}

.file-field.normal .file-field-button {
    width: var(--normal);
}

.file-field.large .file-field-button {
    width: var(--large);
}

.file-field.xlarge .file-field-button {
    width: var(--xlarge);
}