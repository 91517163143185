.subordinates {
    font-family: var(--app-font-family);
    font-weight: var(--app-font-weight);
    font-size: var(--app-font-size);
}

.subordinates-row {
    height: 0;
}

.subordinates-content .row {
    min-height: 64px;
}

.subordinates-content .row:nth-child(odd) {
    background: var(--table-row-odd-bg-color);
}

.subordinates-content .row:nth-child(even) {
    background: var(--table-row-even-bg-color);
}