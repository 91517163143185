
.TableauCalculatriceAgircArrco tbody tr:first-child td{
    padding-top:2px;
}
.TableauCalculatriceAgircArrco td{
    height: 20px;
    padding:2px;
}

.TableauCalculatriceAgircArrco .text-field .input-field input{
    height: 20px;
    font-size: 8pt;
}
.TableauCalculatriceAgircArrco  .text-field div.icon {
    position: absolute;
    width: 8px;
    height: 8px;
    margin: 3px 14px;
    padding: 0;
}
.TableauCalculatriceAgircArrco .drop-down{
    height: 20px;
    flex-direction:unset;
}
.optiIcon {
    height: 44px;
    margin: 0;
    font-size: 0.875rem;
    box-shadow: 1px 1px 2px rgba(0,0,0,.5);
    border-radius: 50%;
    background-color: var(--app-selected-bg-color);
}