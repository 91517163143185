.main-menu {
    width: 80px;
    height: auto !important;
    background-color: var(--main-menu-bg-color);
    color: var(--main-menu-fg-color);
    font-family: var(--menu-font-family);
    font-weight: var(--app-font-weight);
    font-size: var(--menu-font-size);
    box-shadow: 2px 0 4px var(--shadow-color);
    z-index: 3;
}

.main-menu-logo {
    width: 48px;
    height: 48px;
    margin: 8px 16px 32px 16px;
}

.main-menu-icons .button {
    padding: var(--default-padding);
    font-size: 7pt;
    width: 64px;
    height: 64px;
    background-color: transparent;
    box-shadow: none;
    border: 1px solid transparent !important;
}

.main-menu-icons .button svg {
    padding: 0;
    margin: 8px 0 0 0;
    min-width: 24px;
    min-height: 24px; 
    color: var(--header-bg-color);
}

.main-menu-icons .button.disabled svg {
    color: var(--app-disabled-fg-color);
}

.main-menu-icons .button.disabled span {
    color: var(--app-disabled-fg-color);
}

@media (hover: hover) and (pointer: fine) {
    .main-menu-icons .button:not(:disabled):hover {
        border: 1px solid var(--header-bg-color) !important;
        border-radius: var(--default-radius);
    }
}
