.drop-down {
    display: flex;
    flex-direction: column;
    align-content: center;
    position: relative;
}

.drop-down label {
    text-transform: uppercase;
    font-family: var(--label-font-family);
    font-size: var(--label-font-size);
    font-weight: var(--label-font-weight);
    margin: 8px 4px;
}

.drop-down-value {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    border: 1px solid var(--shadow-color);
    border-radius: var(--default-radius);
    font-family: var(--app-default-font);
    font-size: var(--input-font-size);
    font-weight: var(--app-font-weight);
    padding: 0 var(--default-padding);
    margin: 0;
    box-sizing: border-box;
    background-color: var(--input-bg-color);
    color: var(--input-fg-color);
}

.drop-down.disabled .drop-down-value {
    background-color: var(--app-disabled-bg-color);
}

.drop-down-value span {
    flex: 1;
    height: 30px;
    line-height: 30px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.drop-down-value svg {
    margin-right: 8px;
    height: 16px;
}

.drop-down-value svg.arrow {
    margin-right: 0;
}

.drop-down-values {
    display: flex;
    flex-direction: column;
    position: absolute;
    border: 1px solid var(--shadow-color);
    border-radius: calc(2 * var(--default-radius));
    max-height: 270px;
    background-color: var(--app-bg-color);
    box-shadow: var(--default-shadow);
    padding: var(--default-padding);
    z-index: 100000;
    padding: var(--default-padding);
    box-sizing: border-box;
    font-size: 12px;
    overflow: auto;
}

.drop-down-values::-webkit-scrollbar-thumb {
    border-radius: calc(2 * var(--default-radius));
}

.drop-down-values.xs, .drop-down-values.xsmall {
    width: auto;
    min-width: var(--xsmall);
}

.drop-down-values.small {
    width: auto;
    min-width: var(--small);
}

.drop-down-values.normal {
    width: auto;
    min-width: 320px;
}

.drop-down-values.large {
    width: auto;
    min-width: var(--large);
}

.drop-down-values.xlarge {
    width: auto;
    min-width: var(--xlarge);
}
.drop-down-values.xxlarge {
    width: auto;
    min-width: var(--xxlarge);
}

.drop-down-options {
    display: flex;
    flex-direction: column;
    flex: 1 0;
    padding: 0 8px;
}

.drop-down-option {
    padding: 0;
    margin: 0;
}

.drop-down-option h1 {
    display: block;
    width: 100%;
    padding: var(--default-padding);
    font-family: var(--app-font-family);
    font-weight: var(--app-font-bold);
    font-size: 9pt;
    background-color: var(--title-bg-color);
    color: var(--title-fg-color);
    border-bottom: 1px solid var(--title-fg-color);
    text-transform: uppercase;
    box-sizing: border-box;
}

.drop-down-option-content {
    display: flex;
    flex-direction: row;
    height: 24px;
    line-height: 24px;
    align-items: center;
    margin: 2px 0;
    padding: var(--default-margin) var(--default-padding);
    border-radius: var(--default-radius);
}

.drop-down-option-content.selected {
    background-color: var(--app-selected-bg-color);
    color: var(--app-selected-fg-color);
}

.drop-down-option-content:not(.selected):hover {
    background-color: var(--app-hover-bg-color);
    color: var(--app-hover-fg-color);
}

.drop-down-option svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}
