.footer {
    height: 48px;
    background-color: var(--footer-bg-color);
    color: var(--footer-fg-color);
    padding: var(--default-padding);
}

.footer-status {
    justify-content: center;
}

.footer-version {
    justify-content: center;
}