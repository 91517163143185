.button {
    display: flex;
    flex-direction: row;
    background-color: var(--button-bg-color);
    color: var(--button-fg-color);
    border: none;
    border-radius: var(--default-radius);
    box-shadow: var(--default-shadow);
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    min-height: 40px;
    min-width: 48px;
    font-family: var(--button-font-family);
    font-size: var(--app-font-size);
}

.button svg {
    padding: 0 var(--default-padding);
}

@media (hover: hover) and (pointer: fine) {
    .button:not(:disabled):hover {
        filter:brightness(120%);
        border: none;
    }
}

.button:not(:disabled):active {
    transform: translate(2px, 2px);
    box-shadow: none;
}

.button span {
    padding: var(--default-padding);
}

.button.tool {
    flex-direction: column;
}

.button.icon {
    background-color: transparent;
    color: var(--button-bg-color);
    padding: 0;
    box-shadow: none;
    border: none;
    border-radius: 0;
    min-width: 16px;
    min-height: 16px;
}

.button.icon.disabled {
    color: var(--button-disabled-bg-color);
}

.button.xs, .button.xsmall {
    width: var(--xsmall);
}

.button.small {
    width: var(--small);
}

.button.normal {
    width: var(--normal);
}

.button.large {
    width: var(--large);
}

.button.xlarge {
    width: var(--xlarge);
}

button.ghost {
    height: 24px;
    line-height: 24px;
    background-color: transparent;
    color: var(--app-fg-color);
    box-shadow: none;
}