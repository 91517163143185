.menu-title {
    display: block;
    padding: var(--default-margin) var(--default-padding);
    overflow: visible;
}

.menu-title h1 {
    display: block;
    padding: 0;
    margin: 0;
    font-family: var(--app-default-font);
    font-size: var(--menu-title-font-size);
    color: var(--menu-title-fg-color);
    height: 24px;
    line-height: 24px;
    font-weight: var(--app-font-weight);
}

.menu-title h2 {
    display: block;
    padding: 0;
    margin: 0;
    font-family: var(--app-default-font);
    font-size: var(--app-font-small);
    font-weight: var(--app-font-weight);
    color: var(--menu-subtitle-fg-color);
    height: 16px;
    line-height: 16px;
}
