div.col {
    display: flex;
    flex-direction: column;
    height: fit-content;
    padding: var(--default-padding);
}

div.col.fill {
    height: auto !important;
}

div.col.centerX {
    align-items: center;
}

div.col.centerY {
    justify-content: center;
}