.modal-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    background-color: var(--shadow-color);
    backdrop-filter:blur(4px);
    z-index: 10000;
    justify-content: center;
}

.modal {
    display: flex;
    flex-direction: column;
    align-self: center;
    background-color: var(--app-bg-color);
    border-radius: var(--default-radius);
    box-shadow: var(--default-shadow);
}

.modal-header {
    background-color: var(--modal-header-bg-color);
    color: var(--modal-header-fg-color);
    font-size: 12pt;
    font-weight: var(--app-font-weight);
    min-height: 64px !important;
    max-height: 64px;
    align-items: center;
    border-radius: var(--default-radius) var(--default-radius) 0 0;
    overflow: hidden;
}

.modal-header .modal-icon svg {
    width: 20px;
    height: 20px;
    margin-left: var(--default-margin);
}

.modal-header .modal-close svg {
    width: 28px;
    height: 28px;
    margin-right: var(--default-margin);
}
.modal-icon{
    padding: 0 !important;
}
/* .modal-header span {
    padding: 0 var(--default-margin);
} */

.modal-content {
    padding: 0 !important;
    overflow: auto;
}