.blockRoundedForce{
    padding: 8px 16px 8px 16px !important;
    border-radius: var(--default-radius)!important;
    box-shadow: var(--default-shadow) !important;
}

.resultatRemExpress tbody tr:last-child {
    border-bottom: none;
}
table.resultatRemExpress tbody tr:first-child td {
    padding: 0;
}
table.resultatRemExpress td {
    position: relative;
    height: 40px;
    padding: 0;
    margin: 0;
    font-size: 9pt;
}

table.resultatRemExpress th {
    text-align: center;
    vertical-align: center;
}

table.resultatRemExpress tr:nth-child(odd) {
    background: transparent; 
}

table.resultatRemExpress tr:nth-child(even) {
    background: transparent;
}
table.resultatRemExpress tr td {
    text-align: right;
    white-space: nowrap;
}

table.resultatRemExpress tr td:first-child {
    text-align: left;
    white-space: normal;
}

table.resultatRemExpress td.libelle {
    width: 100%;
}

table.resultatRemExpress td.libelleGras {
    width: 100%;
    font-weight: bold;
}

table.resultatRemExpress td.origine {
    min-width: 80px;
}

table.resultatRemExpress td.nouvelle>.text-field {
    float: right;
}
table.resultatRemExpress td.nouvelle {
    min-width: 150px;
}

table.resultatRemExpress td.extra {
    min-width: 30px;
}

table.resultatRemExpress td.ecart {
    min-width: 90px;
}

table.resultatRemExpress td.avant {
    min-width: 125px;
}

table.resultatRemExpress td.apres {
    min-width: 125px;
}
table.resultatRemExpress td.pr4 {
    padding-right:4px !important;
}

.optiIcon {
    height: 44px;
    margin: 0;
    font-size: 0.875rem;
    box-shadow: 1px 1px 2px rgba(0,0,0,.5);
    border-radius: 50%;
    background-color: var(--app-selected-bg-color);
}

.editIconOptiRem {
    position: absolute;
    left: 35px;
    color: var(--app-selected-bg-color) !important;
    margin: 0;
    padding: 0;
}
.optiDetailRem {
    position: absolute;
    left: 7px;
    top: 14px;
}