.menu-item {
    display: flex;
    flex-direction: row;
    position: relative;
    min-height: 48px;
    padding: 0 var(--default-padding);
}

.menu-item:not(.disabled):not(.selected) {
    cursor: pointer;
}

.menu-item.selected {
    background-color: var(--app-selected-bg-color);
    color: var(--app-selected-fg-color);
    border-right: 4px solid var(--app-selected-border-color);
}

.menu-item:hover:not(.disabled) {
    background-color: var(--app-hover-bg-color);
    color: var(--app-hover-fg-color);
}

.menu-item svg {
    position: absolute;
    left: var(--default-padding);
    width: 16px;
    height: 16px;
    align-self: center;
    color: var(--menu-icon-color);
}

.menu-item span {
    align-self: center;
    padding-left: 24px;
}

