.login {
    font-family: var(--app-font-family);
    font-weight: var(--app-font-weight);
    font-size: var(--app-font-size);
}

.login a, .login a:visited {
    text-transform: uppercase;
    font-size: var(--app-font-small);
    color: var(--button-bg-color);
    text-decoration: none;
}

.login a.disabled {
    color: var(--app-disabled-fg-color);
}

