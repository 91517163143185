.menu {
    display: flex;
    flex-direction: column;
    font-family: var(--app-font-family);
    font-weight: var(--app-font-weight);
    font-size: var(--app-font-size);
    box-shadow: 2px 0 4px var(--shadow-color);
    background-color: var(--menu-bg-color);
    color: var(--menu-fg-color);
    margin: 0;
    min-width: 200px;
    max-width: 300px;
    overflow: auto;
}
