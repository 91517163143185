.App.Kwiper {
    --app-bg-color: white;
    --app-fg-color: black;
    --app-font-family: Inter, sans-serif;
    --app-font-weight: 500;
    --app-font-bold: 600;
    --app-font-size: 12px;
    --app-font-small: 8pt;
    --app-disabled-bg-color: lightgrey;
    --app-disabled-fg-color: gray;
    
    --app-selected-bg-color: #2cbeb5;
    --app-selected-fg-color: white;
    --app-selected-border-color: #04A197;

    --app-delete-color: #ff8c00;

    --app-hover-bg-color: #2195f350;
    --app-hover-fg-color: black;

    --xsmall: 80px;
    --small: 120px;
    --normal: 200px;
    --large: 270px;
    --xlarge: 310px;
    --xxlarge: 540px;

    --shadow-color: rgba(0,0,0,.25);

    --table-row-odd-bg-color: transparent;
    --table-row-even-bg-color: rgba(0,0,0,.05);
    --table-row-mono-bg-color: #f0f0ff;
    --table-header-bg-color: #2cbeb5;
    --table-header-fg-color: white;
    --table-header-border-color: #04A197;

    --menu-font-family: Inter, sans-serif;
    --menu-font-size: 8pt;

    --main-menu-bg-color: #304050;
    --main-menu-fg-color: white;

    --header-bg-color: #2cbeb5;
    --header-fg-color: white;

    --content-bg-color: #F8F8F8;
    --content-fg-color: black;

    --footer-bg-color: #2cbeb5;
    --footer-fg-color: white;

    --button-bg-color: #04A197;
    --button-disabled-bg-color: gray;
    --button-fg-color: white;
    --button-font-family: Inter, sans-serif; 

    --title-bg-color: transparent;
    --title-fg-color: #2cbeb5;

    --default-padding: 8px;
    --default-margin: 16px;
    --default-radius: 4px;
    --default-shadow: 2px 2px 4px var(--shadow-color);

    --label-font-family: Inter, sans-serif;
    --label-font-size: 7pt;
    --label-font-weight: 500;

    --input-font-size: 12px;
    --input-active-border-color: #2cbeb5;
    --input-bg-color: white;
    --input-fg-color: black;
    
    --check-box-color: #2cbeb5;

    --menu-bg-color: white;
    --menu-fg-color: black;
    --menu-title-fg-color: #2cbeb5;
    --menu-title-font-size: 12pt;
    --menu-subtitle-fg-color: black;
    --menu-icon-color: #304050;

    --modal-header-bg-color: #04A197;
    --modal-header-fg-color: white;

    --blockquote-border-color: #04A197;
    --blockquote-bg-color: whitesmoke;
    --blockquote-fg-color: black;
}
