.center {
    align-items: center;
}

.left, .top {
    align-items: flex-start;
}

.right, .bottom {
    align-items: flex-end;
}

.fill {
    flex: 1 1 auto;
}

.fit {
    height: fit-content;
}

.rpad {
    margin-right: var(--default-padding);
}

.lpad {
    margin-left: var(--default-padding);
}

.hpad {
    margin-right: var(--default-padding);
    margin-left: var(--default-padding);
}

.tpad {
    margin-top: var(--default-margin);
}

.bpad {
    margin-bottom: var(--default-margin);
}

.vpad {
    margin-top: var(--default-margin);
    margin-bottom: var(--default-margin);
}

.pad {
    margin: var(--default-padding);
}

.nopad {
    margin: 0;
    padding: 0 !important;
}

.wrap {
    flex-wrap: wrap;
}
