div.row {
    display: flex;
    flex-direction: row;
}

div.row.centerX {
    justify-content: center;
}

div.row.centerY {
    align-items: center;
}