table.correctPER tbody tr:last-child {
    border-bottom: none;
}

table.correctPER td {
    height: 52.3px;
}
.CorrecPERInput{
    width: 180px;
}
.CorrecPERInput input{
    width: 180px;
}
