table {
    border: none;
    border-collapse: collapse;
    padding: 0;
    margin: 0;
    font-family: var(--app-font-family);
    font-size: var( --app-font-size);
    width: 100%;
}


table td {
    height: 20px;
}

/* tr:nth-child(odd) {
    background: var(--table-row-odd-back-color); 
}

tr:nth-child(even) {
    background: var(--table-row-even-back-color); 
} */


th { 
    font-weight: var(--app-font-weight);
    padding: 8px;
    text-align: left;
    background-color: var(--app-selected-bg-color);
    color: var(--button-fg-color);
}

td {
    padding: 8px;
}

.table-placeholder {
    display: block;
    margin: 16px 0 16px 0;
}

table.mono tr:nth-child(odd) {
    background: #f0f0ff; 
}

table.mono tr:nth-child(even) {
    background: #f0f0ff; 
}

.text-center{
   text-align: center; 
}
.text-right{
   text-align: right; 
}

#GraphIJ svg,#GraphInval svg,#GraphDispoRetraiteMadelin svg,#GraphDispoPrevSanteMadelin svg,#GraphDispoPerpTNS svg,#GraphSocialRetraite83 svg,
#GraphSocialPrev83 svg,#GraphFiscalRetraite83 svg,#GraphFiscalPrev83 svg,#GraphDispoPerpSAL svg{
    width: 100%;
}    
