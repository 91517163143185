.check-box {
    display: flex;
    flex-direction: column;
    align-content: center;
    position: relative;
}

.check-box.inline {
    flex-direction: row;
}

.check-box label {
    text-transform: uppercase;
    font-family: var(--label-font-family);
    font-size: var(--label-font-size);
    font-weight: var(--label-font-weight);
    margin: 8px 4px;
}

.check-box.inline label {
    text-transform: none;
    font-size: var(--input-font-size);
    padding-left: 32px;
    padding-right: 0;
    margin: 9px 0;
}

.check-box.inline.before label {
    padding-left: 0;
    padding-right: 32px;
}

.check-box .icon {
    color: var(--check-box-color);
    margin-top: 4px;
}

.check-box.disabled .icon {
    color: var(--app-disabled-bg-color);
}

.check-box.inline .icon {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin: 0;
}

.check-box.inline.before .icon {
    right: 0;
}

.check-box .icon svg {
    align-self: center;
    width: 20px;
    height: 20px;
}
