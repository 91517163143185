.companies {
    font-family: var(--app-font-family);
    font-weight: var(--app-font-weight);
    font-size: var(--app-font-size);
}

.companies-row {
    height: 0;
}

.companies-content tbody tr {
    height: 64px;
}

.companies-content tbody img {
    height: 48px;
}