.header {
    min-height: 64px;
    background-color: var(--header-bg-color);
    color: var(--header-fg-color);
    box-shadow: 0 2px 4px var(--shadow-color);
    z-index: 2;
}

.brand {
    margin-left: 16px;
    justify-content: center;
}

.brand-name {
    font-weight: 500;
    font-size: 12pt;
}

.brand-slogan {
    font-size: 8pt;
}